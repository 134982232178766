import React from 'react'
import { MapPin, Smartphone, Mail, Facebook, Twitter, Linkedin } from 'react-feather'
import { graphql } from 'gatsby'
import { FacebookProvider, Page } from 'react-facebook';

import NetlifyForm from 'react-netlify-form'
import Content from '../components/Content'
import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import './ContactPage.css'
import '../components/Form.css'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({
  body,
  address,
  phone,
  email,
  facebook,
  twitter,
  linkedin,
  locations
}) => (
  <main className="Contact">
    <GoogleMap locations={locations} />
    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
          <Content source={body} />
          <div className="Contact--Details">
            <h1>Contact Us</h1>
            <p>We are always open 24/7 <br />
            Send us a message via Whatsapp or FB messenger for faster response</p><br />

            {address && (
              <a
                className="Contact--Details--Item"
                href={`https://www.google.com/maps/search/${encodeURI(
                  address
                )}`}
                target="_blank"
                hreflang="en"
                rel="noopener noreferrer"
              >
                <MapPin /> {address}
              </a>
            )}
            {phone && (
              <a className="Contact--Details--Item" href={`tel:${phone}`} hreflang="en">
                <Smartphone /> {phone}
              </a>
            )}
            {email && (
              <a className="Contact--Details--Item" href={`mailto:${email}`} hreflang="en">
                <Mail /> {email}
              </a>
            )}
            {facebook && (
              <a className="Contact--Details--Item" hreflang="en" href="https://www.facebook.com/philodotshop" rel="noopener noreferrer">
                <Facebook /> {facebook}
              </a>
            )}
            {twitter && (
              <a className="Contact--Details--Item" hreflang="en" href="https://twitter.com/philodotshop" rel="noopener noreferrer">
                <Twitter /> {twitter}
              </a>
            )}
            {linkedin && (
              <a className="Contact--Details--Item" hreflang="en" href="https://www.linkedin.com/company/philodotshop" rel="noopener noreferrer">
                <Linkedin /> {linkedin}
              </a>
            )}
            
          </div>
        </div>

        <div>
          <NetlifyForm name='Contact Form' data-netlify-recaptcha="true">
              {({ loading, error, success }) => (
                <div>
                  {loading &&
                    <div>Loading...</div>
                  }
                  
                  {!loading && !success &&
                    <div style={{marginTop: 30}}>
                      <div className="Form--Group">
                        <label className="Form--Label">
                          <input className="Form--Input Form--InputText" type="text" placeholder="Firstname" name="firstname" required />
                          <span>Firstname</span>
                        </label>
                        <label className="Form--Label">
                          <input className="Form--Input Form--InputText" type="text" placeholder="Lastname" name="lastname" required />
                          <span>Lastname</span>
                        </label>
                      </div>
                      <label className="Form--Label">
                        <input
                          className="Form--Input Form--InputText Form-email"
                          type="email"
                          placeholder="Email"
                          name="emailAddress"
                          required
                        />
                        <span>Email address</span>
                      </label>
                      <label className="Form--Label has-arrow">
                        <select
                          className="Form--Input Form--Select"
                          name="type"
                        >
                          <option>Request a service</option>
                          <option>Found a bug</option>
                          <option>Looking for a job</option>
                          <option>Guest Blogging</option>
                        </select>
                      </label>
                      <label className="Form--Label">
                        <textarea
                          className="Form--Input Form--Textarea Form--InputText"
                          placeholder="Message"
                          name="message"
                          defaultValue=""
                          rows="10"
                          required
                        />
                        <span>Message</span>
                      </label>
                      <label className="Form--Label Form--Input fileupload">
                        <h6>Upload File (optional)</h6>
                        <input
                          className="Form--Textarea"
                          name="fileupload"
                          type="file"
                        />

                      </label>
                      <label className="Form--Label Form-Checkbox">
                        <input
                          className="Form--Input Form--Textarea Form--CheckboxInput"
                          name="newsletter"
                          type="checkbox"
                        />
                        <span>Get news updates</span>
                      </label>
                      <div data-netlify-recaptcha="true"></div>
                      <button className="Button Form--SubmitButton">Submit</button>
                    </div>
                  }
                  {error &&
                    <div>
                      <div className="Form--Alert">Your information was not sent. Please contact us via email, Whatsapp or Messenger.</div>
                      <div className="flex-bumi">
                        <div className="earth filter"/>
                      </div>
                    </div>
                  }
                  {success &&
                    <div>
                      <div className="Form--Alert">Your messages have been received. Thank you for contacting us!</div>
                      <div className="flex-bumi">
                        <div className="earth filter"/>
                      </div>
                    </div>
                  }
                </div>
              )}
            </NetlifyForm>
        </div>
      </div>
    </section>
    <div className="fbm">
    <p align="center">
      <FacebookProvider appId="363762830961156">
        <Page href="https://www.facebook.com/philodotshop" width="500px" tabs="messages" adaptContainerWidth="true" smallHeader="true" height="280px" />
      </FacebookProvider>
    </p>
    </div>
  </main>
)

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        address
        phone
        email
        facebook
        twitter
        linkedin
        locations {
          mapLink
          lat
          lng
        }
      }
    }
  }
`
